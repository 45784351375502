.rpb-simple-content-half-width {
  .hc-half-content {
    p {
      &:last-of-type {
        margin-bottom:0;
      }
    }
  }
  .hc-half-content-image {
    min-height:250px;
  }
}

.rpb-simple-content-full-width {
  .hc-content {
    p {
      &:last-of-type {
        margin-bottom:0;
      }
    }
  }
}

.hc-half-content-image {
  @include media-breakpoint-up(lg) {
    position: absolute;
    width: 50%;
    border-radius: 200px 0 0 25px;
    right: 0;
    &.left {
      left: 0;
      right: auto;
      border-radius: 0 200px 25px 0;
    }
  }
}