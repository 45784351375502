// Theme Colors/Branding
$brand-primary: #1d62e9;
$brand-primary-rgb: rgba($brand-primary,1);
$brand-primary-offset: darken($brand-primary,10%);
$brand-secondary: #85e8ff;
$dark: #000;
$light: #f5f5f5;
$medium: #dee2e6;
$inactive: #8c8c8c;
$gray: #888;
$white: #fff;
$accent: #f10503;
$accent-2: #f10503;
$body-font: #222;
$error: #f10503;