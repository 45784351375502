.sidebar {
  .widget {
    background: white;
    border-radius:$border-radius;
    margin-bottom: 2rem;
    &:last-child {
      border-bottom: 0px;
    }
    h3,
    .h3 {
      background: $brand-secondary;
      border-top-left-radius:$border-radius;
      border-top-right-radius:$border-radius;
      color: #fff;
      padding: 15px;
    }
    .primary-btn {
      @include media-breakpoint-down(lg) {
        font-size: 13px;
        line-height: 1.5em;
      }
    }

    .sidebar-buttons .btn {
      display: flex;
      margin-bottom: 1.5rem;
      width: 100%;
      justify-content: space-between;
      align-items: center;
    }

  }
}
